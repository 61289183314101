<template>
  <div class="container roit-aitax">
    <div
      class="container"
      style="
        background-color: #fff;
        border-radius: 10px;
        padding-bottom: 15px;
        padding-top: 15px;
        padding-left: 10px;
        width: 680px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -372px;
        margin-top: -300px;
        padding-left: 100px;
      "
    >
      <div class="col-12 center">
        <div class="input" style="margin-left: -85px">
          <el-input
            placeholder="Nome Sobrenome"
            v-model="dados.nome"
            v-validate="'required'"
            name="nome"
          ></el-input>
          <el-input
            placeholder="Departamento"
            v-model="cargo"
            v-validate="'required'"
            name="cargo"
          ></el-input>
          <the-mask
            class="el-input__inner"
            :mask="['+## ## ####-####']"
            :masked="true"
            v-model="dados.telefone"
            placeholder="Telefone"
            v-validate="'required'"
            name="telefone"
          />
          <the-mask
            class="el-input__inner"
            :mask="['+## ## #####-####', '+## ## ####-####']"
            :masked="true"
            v-model="dados.celular"
            placeholder="Celular"
            name="celular"
          />
          <el-input
            placeholder="Hangouts"
            v-model="dados.hangouts"
            v-validate="'required'"
            name="hangouts"
          ></el-input>
          <el-input
            placeholder="https://www.linkedin.com/in/"
            v-model="dados.linkedin"
            v-validate="'required'"
            name="linkedin"
          ></el-input>
          <!-- <el-input placeholder="Skype" v-model="dados.skype" v-validate="'required'" name="skype"></el-input> -->
        </div>
        <div
          style="
            position: relative;
            width: fit-content;
            margin-left: -100px;
            margin-top: 20px;
            margin-bottom: 15px;
            min-width: max-content;
          "
          ref="signature"
        >
          <img :src="urlAiTax" width="650" />
          <p class="name">{{ dados.nome }}</p>
          <p class="cargo">{{ cargo }}</p>
          <p class="info" id="celular">{{ dados.celular }}</p>
          <div id="input-hider" v-if="!dados.celular"></div>
          <p class="info" id="telefone">{{ dados.telefone }}</p>
          <p class="info" id="hangouts">{{ dados.hangouts }}</p>
          <!-- <p class="info" id="skype">{{ dados.skype }}</p> -->
          <qrcode
            :foreground="'#bda059'"
            type="image"
            :size="size"
            :value="qrText"
            class="qrcode"
          ></qrcode>
        </div>
        <el-button
          type="primary"
          style="margin-left: -60px"
          @click="prepareToPrint"
          >Download</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Qrcode from "v-qrcode";

export default {
  data() {
    return {
      dados: {
        nome: "",
        celular: "",
        telefone: "",
        hangouts: "",
        linkedin: "",
      },
      cargo: "",
      urlAiTax: require("../../assets/img/aitax_signature.png"),
      qrText: "https://roit.ai",
      // qrText: "BEGIN:VCARD "
      //       + "\r\nFN:"+ "Paulino Marques"
      //       + "\r\nORG: ROIT"
      //       + "\r\nTITLE: " + "Marketing"
      //       + "\r\nURL: roit.ai"
      //       + "\r\nTEL;TYPE=Cell:" + "+55 41 98861-3233"
      //       + "\r\nTEL;TYPE=Work:" + "+55 41 3076-5005"
      //       + "\r\nNOTE:Hangouts: " + "paulino.marques"
      //       + "\r\nEND:VCARD",
      output: "",
      size: 1500,
    };
  },
  components: {
    Qrcode,
  },
  methods: {
    async print() {
      // this.size = (this.dados.celular !== "")? 1500 : 250
      // this.qrText = "BEGIN:VCARD\r\nFN:" + this.dados.nome + " \r\nTEL;TYPE=cell:" + this.dados.telefone + "\r\nEMAIL:"+this.dados.hangouts+"\r\nEND:VCARD"
      this.qrText =
        "BEGIN:VCARD " +
        "\r\nVERSION:3.1" +
        "\r\nN:" +
        this.dados.nome +
        "\r\nFN:" +
        this.dados.nome +
        "\r\nORG:ROIT" +
        "\r\nTITLE:" +
        this.cargo +
        "\r\nURL: roit.ai" +
        "\r\nTEL;CELL:" +
        this.dados.celular +
        "\r\nTEL;WORK:" +
        this.dados.telefone +
        "\r\nNOTE:Hangouts: " +
        this.dados.hangouts +
        (this.dados.linkedin ? "\rLinkedIn: " + this.dados.linkedin : "") +
        "\r\nEND:VCARD";

      const el = this.$refs.signature;
      this.output = await this.$html2canvas(el, {
        type: "dataURL",
      });
      var url = this.output.replace(
        /^data:image\/[^;]+/,
        "data:application/octet-stream"
      );

      var downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "assinatura.png";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    prepareToPrint() {
      this.print();
      // this.$validator.validateAll().then((result) => {
      //   if (result) {
      //     return;
      //   }
      // });
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
.roit-aitax {
  input {
    margin-top: 10px;
  }

  .center {
    text-align: center;
  }

  .info {
    color: #282044;
    font-size: 12px;
    position: absolute;
    font-weight: 600;
    width: 140px;
    text-align: initial;
  }

  .info#telefone {
    top: 67px;
    left: 301px;
    color: #282044;
  }

  .info#hangouts {
    top: 81px;
    left: 303px;
    color: #282044;
    width: 140px !important;
    overflow: hidden;
    text-transform: lowercase;
    color: #282044;
  }

  .info#celular {
    top: 50px;
    left: 301px;
    color: #282044;
    max-width: 140px;
    overflow: hidden;
    letter-spacing: -0.5px;
  }
  .qrcode {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 11px;
    right: 6.5px;
    width: 122px;
    height: 122px;
  }
  .qrcode img {
    width: 122px;
    height: 122px;
  }
  p {
    font-family: "Montserrat", sans-serif;
  }

  p.name {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 900;
    color: #282044;
    position: absolute;
    top: 6px;
    left: 289px;
    max-width: 300px;
    width: 382px;
    overflow: hidden;
    text-align: initial;
    max-height: 33px;
  }

  p.cargo {
    font-size: 13px;
    font-weight: 600;
    color: #282044;
    top: 28px;
    position: absolute;
    left: 289px;
    max-width: 382px;
    width: 382px;
    text-align: initial;
    max-height: 25px;
    overflow: hidden;
  }
  input {
    margin-top: 10px;
  }

  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }

  #input-hider {
    position: absolute;
    top: 52px;
    left: 290px;
    background-color: white;
    width: 15px;
    height: 15px;
  }
}
</style>
