import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ptbrLocale from 'element-ui/lib/locale/lang/pt-br'
import locale from 'element-ui/lib/locale'
import VueHtml2Canvas from 'vue-html2canvas';
import VueTheMask from 'vue-the-mask'
import VeeValidate from "vee-validate";
import './assets/fonts/fonts.css';
Vue.use(VueTheMask)
Vue.use(VueHtml2Canvas);
Vue.use(ElementUI);
Vue.use(VeeValidate, {
  classes: true
});

locale.use(ptbrLocale)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
