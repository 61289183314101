<template>
  <div id="app">
    <EmailSignature/>
  </div>
</template>

<script>

import EmailSignature from './components/EmailSignature.vue'

export default {
  name: 'app',
  components: {
    EmailSignature
  }
}
</script>

<style>
html {
  /* background: url('../assets/img/wall.png') no-repeat; */
  /* background-size: auto; */
  background-color: #186891;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizeLegibility !important;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
