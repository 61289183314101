<template>
  <div class="container">
    <transition name="modal" id="modal-template" v-if="showModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <span class="position-absolute" @click="showModal = false">X</span>
            <div class="modal-header p-0">
              <slot name="header">
                <h3 class="py-4">Selecione o tipo de Assinatura</h3>
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
                <button class="mx-4" @click="showAssinatura('Roit')">
                  ROIT
                </button>
                <button @click="showAssinatura('AiTax')">AiTAX</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <component :is="component" />
  </div>
</template>

<script>
import Roit from "../components/subscriptions/Roit.vue";
import AiTax from "../components/subscriptions/AiTax.vue";

export default {
  name: "EmailSignature",
  components: { Roit, AiTax },
  data() {
    return {
      showModal: true,
      component: "",
    };
  },
  methods: {
    showAssinatura(showComponent) {
      this.component = showComponent;
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
.modal-header {
  display: flex;
  justify-content: center;

  h3 {
    font-size: 20px;
  }
}
.modal-container {
  position: relative;
  width: 400px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  span {
    top: 2px;
    right: 6px;
    cursor: pointer;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-body {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  button {
    outline: none !important;
    border: 0;
    padding: 5px 35px;
    border-radius: 16px;
    background: #045176;
    color: #fff;
  }
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
