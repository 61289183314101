<template>
  <div class="container roit-bank">
    <div class="container">
      <div class="col-12 text-center">
        <div class="input forms">
          <el-input
            placeholder="Nome Sobrenome"
            v-model="dados.nome"
            v-validate="'required'"
            name="nome"
          ></el-input>
          <el-input
            placeholder="Cargo"
            v-model="cargo"
            v-validate="'required'"
            name="cargo"
          ></el-input>
          <the-mask
            class="el-input__inner"
            :mask="['+## ## ####-####']"
            :masked="true"
            v-model="dados.telefone"
            placeholder="Telefone"
            v-validate="'required'"
            name="telefone"
          />
          <the-mask
            class="el-input__inner"
            :mask="['+## ## #####-####', '+## ## #####-####']"
            :masked="true"
            v-model="dados.celular"
            placeholder="Celular"
            name="celular"
          />
          <el-input
            placeholder="Hangouts"
            v-model="dados.hangouts"
            v-validate="'required'"
            name="hangouts"
          ></el-input>
          <!-- <el-input
            placeholder="https://www.linkedin.com/in/"
            v-model="dados.linkedin"
            v-validate="'required'"
            name="linkedin"
          ></el-input> -->
        </div>
        <div style="" class="signature" ref="signature">
          <img :src="url" width="650" />
          <p class="name text-uppercase">{{ dados.nome }}</p>
          <p class="cargo mt-3 pt-1">{{ cargo }}</p>
          <p class="info" id="celular">{{ dados.celular }}</p>
          <div id="input-hider" v-if="!dados.celular"></div>
          <p class="info" id="telefone">{{ dados.telefone }}</p>
          <p class="info" id="hangouts">{{ dados.hangouts }}</p>
          <qrcode
            :foreground="'black'"
            type="image"
            :size="size"
            :value="qrText"
            class="qrcode"
          ></qrcode>
        </div>
        <el-button type="primary" @click="prepareToPrint">Download</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Qrcode from "v-qrcode";

export default {
  data() {
    return {
      dados: {
        nome: "",
        celular: "",
        telefone: "",
        hangouts: "",
        linkedin: "",
      },
      cargo: "",
      url: require("../../assets/img/assinatura_email_650x155.png"),
      qrText: "https://roit.ai",
      output: "",
      size: 1500,
    };
  },
  components: {
    Qrcode,
  },
  methods: {
    async print() {
      this.qrText =
        "BEGIN:VCARD " +
        "\r\nVERSION:3.1" +
        "\r\nN:" +
        this.dados.nome +
        "\r\nFN:" +
        this.dados.nome +
        "\r\nORG:ROIT" +
        "\r\nTITLE:" +
        this.cargo +
        "\r\nURL: roit.ai" +
        "\r\nTEL;CELL:" +
        this.dados.celular +
        "\r\nTEL;WORK:" +
        this.dados.telefone +
        "\r\nNOTE:Hangouts: " +
        this.dados.hangouts +
        (this.dados.linkedin ? "\rLinkedIn: " + this.dados.linkedin : "") +
        "\r\nEND:VCARD";

      const el = this.$refs.signature;
      this.output = await this.$html2canvas(el, {
        type: "dataURL",
      });
      var url = this.output.replace(
        /^data:image\/[^;]+/,
        "data:application/octet-stream"
      );

      var downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "assinatura.png";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    prepareToPrint() {
      this.print();
    },
  },
};
</script>

<style lang="scss">
.roit-bank {
    font-family: 'Poppins', sans-serif !important;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
    font-size: 1rem;
  .container {
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 10px;
    width: 680px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -372px;
    margin-top: -300px;
    padding-left: 100px;
  }
  .forms {
    margin-left: -85px;
    input {
      margin-top: 10px;
    }
  }

  .qrcode {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 15px;
    right: 17px;
    width: 122px;
    height: 122px;

    img {
      width: 82px;
      height: 82px;
      margin-top: 25px;
    }
  }
  .signature {
    position: relative;
    width: fit-content;
    margin-left: -100px;
    margin-top: 20px;
    margin-bottom: 15px;

    p {
      overflow: hidden;
      text-align: initial;
      position: absolute;
      -webkit-font-smoothing: antialiased!important;
      -moz-osx-font-smoothing: grayscale!important;
      -webkit-text-size-adjust: none;
      font-weight: 500;
    }

    .name {
      font-size: 16px;
      font-weight: 500;
      color: #E2B874;
      top: 31px;
      left: 192px;
      width: 382px;
    }

    .cargo {
      font-size: 11px;
      color: #fff;
      top: 32px;
      left: 192px;
      width: 382px;
    }

    .info {
      color: #fff;
      font-size: 9px;
      position: absolute;
      width: 140px;
      text-align: initial;
      font-weight: 500;
      -webkit-font-smoothing: antialiased!important;
      -moz-osx-font-smoothing: grayscale!important;
      -webkit-text-size-adjust: none;
    }

    #telefone {
      top: 81px;
      left: 207px;
    }

    #hangouts {
      top: 81px;
      right: 162px;
      text-transform: lowercase;
    }

    #celular {
      top: 101px;
      left: 207px;
    }
  }
}

.el-button {
  margin-left: -60px !important;
}
</style>
